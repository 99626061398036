<template>
  <static-template>
    <h1>NOA GALLERY OF NEO ONE ART INC.</h1>
    <br />
    <h2>Terms and Conditions</h2>
    <br />
    <br />
    <p>{{ $t("tncgP1") }}</p>
    <p>{{ $t("tncgP2") }}</p>
    <p>{{ $t("tncgP3") }}</p>
    <br />
    <h3>{{ $t("tncgl1") }}</h3>
    <p>{{ $t("tncgl1p1") }}</p>
    <p>{{ $t("tncgl1p2") }}</p>
    <p>{{ $t("tncgl1p3") }}</p>
    <p>{{ $t("tncgl1p4") }}</p>
    <p>{{ $t("tncgl1p5") }}</p>
    <br />
    <h3>{{ $t("tncgl2") }}</h3>
    <p>{{ $t("tncgl2p1") }}</p>
    <p>{{ $t("tncgl2p2") }}</p>
    <p>{{ $t("tncgl2p3") }}</p>
     <ol>
      <p>{{ $t("tncgl2p3p1") }}</p>
      <p>{{ $t("tncgl2p3p2") }}</p>
      <p>{{ $t("tncgl2p3p3") }}</p>
    </ol>
    <p>{{ $t("tncgl2p4") }}</p>
    <p>{{ $t("tncgl2p5") }}</p>
    <br />
    <h3>{{ $t("tncgl3") }}</h3>
    <p>{{ $t("tncgl3p1") }}</p>
    <br />
    <h3>{{ $t("tncgl4") }}</h3>
    <p>{{ $t("tncgl4p1") }}</p>
    <p>{{ $t("tncgl4p2") }}</p>
    <br />
    <h3>{{ $t("tncgl5") }}</h3>
    <p>{{ $t("tncgl5p1") }}</p>
    <p>{{ $t("tncgl5p2") }}</p>
    <br />
    <h3>{{ $t("tncgl6") }}</h3>
    <p>{{ $t("tncgl6p1") }}</p>
    <p>{{ $t("tncgl6p2") }}</p>
    <p>{{ $t("tncgl6p3") }}</p>
    <br />
    <h3>{{ $t("tncgl7") }}</h3>
    <p>{{ $t("tncgl7p1") }}</p>
    <br />
    <h3>{{ $t("tncgl8") }}</h3>
    <p>{{ $t("tncgl8p1") }}</p>
    <br />
    <h3>{{ $t("tncgl9") }}</h3>
    <p>{{ $t("tncgl9p1") }}</p>
    <br />
    <h3>{{ $t("tncgl10") }}</h3>
    <p>{{ $t("tncgl10p1") }}</p>
    <br />
    <h3>{{ $t("tncgl11") }}</h3>
    <p>{{ $t("tncgl11p1") }}</p>
    <br />
    <h3>{{ $t("tncgl12") }}</h3>
    <p>{{ $t("tncgl12p1") }}</p>
    <p>{{ $t("tncgl12p2") }}</p>
    <p>{{ $t("tncgl12p3") }}</p>
    <br />
    <h3>{{ $t("tncgl13") }}</h3>
    <p>{{ $t("tncgl13p1") }}</p>
    <p>{{ $t("tncgl13p2") }}</p>
    <p>{{ $t("tncgl13p3") }}</p>
    <br />
    <h3>{{ $t("tncgl14") }}</h3>
    <p>{{ $t("tncgl14p1") }}</p>
    <p>{{ $t("tncgl14p2") }}</p>
    <p>{{ $t("tncgl14p3") }}</p>
    <p>{{ $t("tncgl14p4") }}</p>
    <br />
    <h3>{{ $t("tncgl15") }}</h3>
    <p>{{ $t("tncgl15p1") }}</p>
    <br />
    <h3>{{ $t("tncgl16") }}</h3>
    <p>{{ $t("tncgl16p1") }}</p>
    <br />
    <h3>{{ $t("tncgl17") }}</h3>
    <p>{{ $t("tncgl17p1") }}</p>
    <p>{{ $t("tncgl17p2") }}</p>
    <br />
    <h3>{{ $t("tncgl18") }}</h3>
    <p>{{ $t("tncgl18p1") }}</p>
    <br />
    <h3>{{ $t("tncgl19") }}</h3>
    <p>{{ $t("tncgl19p1") }}</p>
    <p>{{ $t("tncgad1") }}</p>
    <p>{{ $t("tncgad2") }}</p>
    <p>{{ $t("tncgad3") }}</p>
    <p>{{ $t("tncgad4") }}</p>
    <br />
    <br />
    <p>{{ $t("tncgad5") }}</p>
  </static-template>
</template>

<script>
export default {
  name: "TermAndCondGallery",
};
</script>
